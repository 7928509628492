import { useEffect } from 'react';
import { useGtmContext } from '@vaimo/google-tag-manager/src/context/gtm';
import { mapCoupons, mapProductForCheckoutEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';

export const useAddPaymentInfo = ({ checkoutStep, orderDetailsData }) => {
    const [{ isEnabled }] = useGtmContext();

    useEffect(() => {
        if (
            !isEnabled ||
            !orderDetailsData?.cartItems?.length ||
            !orderDetailsData.cartItems.every((item) => item.prices) ||
            !orderDetailsData?.paymentMethod ||
            checkoutStep < 4
        ) {
            return;
        }

        actionsTagManager.resetEcommerce();
        actionsTagManager.addPaymentInfo({
            coupon: mapCoupons(orderDetailsData),
            items: orderDetailsData.cartItems.map(mapProductForCheckoutEvents),
            paymentMethod: orderDetailsData.paymentMethod,
        });
        actionsTagManager.addPaymentInfoHeadless();
    }, [checkoutStep, isEnabled, JSON.stringify(orderDetailsData)]);
};
