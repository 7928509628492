import { useEffect } from 'react';
import { mapCoupons, mapProductForCheckoutEvents } from '@vaimo/google-tag-manager/src/helpers/flattenProductData';
import actionsTagManager from '@vaimo/google-tag-manager/src/helpers/actions';
import { useGtmContext } from '../context/gtm';

export const useReviewOrder = ({ checkoutStep, orderDetailsData }) => {
    const [{ isEnabled }] = useGtmContext();

    useEffect(() => {
        if (
            !isEnabled ||
            !orderDetailsData?.cartItems?.length ||
            !orderDetailsData.cartItems.every((item) => item.prices) ||
            checkoutStep !== 4
        ) {
            return;
        }

        actionsTagManager.resetEcommerce();
        actionsTagManager.reviewOrder({
            coupon: mapCoupons(orderDetailsData),
            items: orderDetailsData.cartItems.map(mapProductForCheckoutEvents),
        });
    }, [checkoutStep, isEnabled, JSON.stringify(orderDetailsData)]);
};
